import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-3-1"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_3/4-3-1-bg__mobile.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    animationInfo: allMdx(filter: {frontmatter: {language: {eq: "HU"}, title: {eq: "4-3-1-mobile-anim"}}}) {
      edges {
        node {
          frontmatter {
            country1
            country2
            counter1
            counter2
            frontCounter1
            frontCounter2
          }
        }
      }
    },
  }
  `);
  return (
    <Template query={query} title="Szia III" />
  );
};


export default Slide;
